import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, LoadingController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { RealclinicsService } from 'src/app/services/realclinics.service';
import { PagamentoComponent } from '../pagamento/pagamento.component';
import { AgendamentoPage } from 'src/app/pages/agendamento/agendamento.page';


@Component({
  selector: 'app-status-exame',
  templateUrl: './status-exame.component.html',
  styleUrls: ['./status-exame.component.scss'],
})
export class StatusExameComponent implements OnInit {

  constructor(
    private navParams: NavParams, 
    private dataService: DataService, 
    private modalCtrl: ModalController,
    private loadCtrl: LoadingController,
    private realclinics: RealclinicsService
  ) { }

  private data: any = this.navParams.data; 
  private modal: any = this.data.modal;
  private user: any = this.data.user;
  private exame: any = this.data.data;
  private contatos: any;
  private unicos: any = [];
  private agendamentoModal: any;
  private loading: any;

  async ngOnInit() {
    let telefones = await this.dataService.requestPost({unidade_id: this.exame.unidade_id}, 'telefones/agendamento');
    this.contatos = telefones;
  }

  close(){
    this.modal.dismiss();
  }

  returnStatus(s){
    const status = {
      'PENDING': 'Aguardando pagamento',
      'CONFIRMED': 'Pagamento confirmado',
      'PAYMENT_OVERDUE': 'Boleto vencido',
      'AWAITING_RISK_ANALYSIS': 'Pagamento em análise',
      'PAYMENT_RECEIVED': 'Pagamento recebido'
    };
    return status[s] || 'Não foi possível processar o pagamento.';
  }

  returnLocal(logradouro, numero, bairro, cidade, estado, complemento, cep){
    let endereco = `${logradouro}, ${numero}`;
    if(complemento){
      endereco += ', ' + complemento;
    }
    endereco += `, ${bairro}, ${cidade} - ${estado} - ${cep}`;
    return endereco;
  }

  async getPagamento(id){
    let pagamento = await this.dataService.requestPost({pagamento: id}, 'pagamento/buscar');
    let modal = await this.modalCtrl.create({
      component: PagamentoComponent,
      componentProps: pagamento
    });
    return await modal.present();
  }

  async marcarAgendamento(){
    await this.agendando();
    let convenio: any = await this.realclinics.getConvenio();
    console.log(convenio);
    let unidade: any = await this.realclinics.getEmpresas();
    console.log(unidade);
    let especialidade: any = await this.realclinics.getEspecialidade(unidade, convenio, "ULTRASSONOGRAFIA");
    console.log(especialidade);
    let procedimento: any = await this.realclinics.getProcedimentos("CONSULTA MEDICA");
    console.log(procedimento);
    let horarios: any = await this.realclinics.getHorarios(unidade, convenio, especialidade, procedimento);
    let marcarcao = this.onlyUnique(horarios);
    return false;
    this.loading.dismiss();
    if(this.agendamentoModal){
      this.agendamentoModal.dismiss();
    }
    this.agendamentoModal = await this.modalCtrl.create({
      component: AgendamentoPage, 
      backdropDismiss: false,
      componentProps: {
        disponibilidade: marcarcao,
        dados: horarios
      },
      cssClass: 'modal-filtro'
    });
    this.agendamentoModal.onDidDismiss().then(async (data) =>{
      console.log(data);
      if(data.data){
        if(data.data.conf == 'aprovado'){
          await this.agendando();
          let dadosAgendamento = data.data.dadosAgendamento;
          console.log(this.user);
          let paciente: any = await this.realclinics.getPaciente(this.user.nome.toString(), this.user.data_nascimento.toString(), this.user.cpf.toString());
          console.log(paciente);
          if(!paciente){
            paciente = await this.realclinics.setPaciente(this.user.nome, this.user.data_nascimento, this.user.cpf);
          }
          console.log(dadosAgendamento);
          this.loading.dismiss();
        }
      }
    });
    return await this.agendamentoModal.present();
  }

  onlyUnique(array) {
    array.forEach(value => {
      let tempo = value.DataHora.split("T");
      let i: any = this.unicos.map(e => e.data).indexOf(tempo[0]);
      if(i > -1){
        this.unicos[i].disponibilidade.push({hora: tempo[1]});
      }else{
        this.unicos.push({data: tempo[0], disponibilidade: [{hora: tempo[1]}]});
      }
    });
    return this.unicos;
  }

  async agendando(){
    this.loading = await this.loadCtrl.create({
      message: 'Aguarde'
    });
    return await this.loading.present();
  }
}
