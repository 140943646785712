import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RealclinicsService {

  constructor(
    private client: HttpClient
  ) { }

  private url:string = 'https://totalsaude.realclinic.com.br/totalsaude/api/';
  private headers: any = {
    "Accept": "application/json, text/plain, /,charset=utf-8",
    "Content-Type": "application/json",
  };

  setAutToken(auth: string, cookie: string){
    this.headers['Cookie'] = "Cookie: RC_SessionId=9a29d3584fa0b69391b6a76c1bb9a1fd;"; //9a29d3584fa0b69391b6a76c1bb9a1fd";
    this.headers['Authorization'] = "Basic dGRzYTo5YTI5ZDM1ODRmYTBiNjkzOTFiNmE3NmMxYmI5YTFmZA==";
  }

  private async request(endpoint: string, vals: any = {}){
    return new Promise((resolve, reject) =>{
      this.client.post(this.url + endpoint, vals, {
        headers: this.headers
      }).subscribe((data) =>{
        console.log(data);
        resolve(data);
      }, (err) =>{
        console.log(err);
        reject(err);
      });
    });  
  }

  async getEmpresas(){
    this.setAutToken("", "");
    let endpoint = 'EmpresaIntegracao/PesquisarUnidadesConsulta';
    let data = await this.request(endpoint);
    return data[0].Id;
  }

  async getConvenio(){
    this.setAutToken("", "");
    let endpoint = 'ConvenioIntegracao/Pesquisar';
    let data: any = await this.request(endpoint);
    let particular = data.find(x => x.Nome.toUpperCase() == 'PARTICULAR');
    if(!particular){
      return false;
    } 
    return particular.Id;
  }

  async getProcedimentos(proced: string){
    this.setAutToken("", "");
    let endpoint = 'ProcedimentoIntegracao/PesquisarProcedimentos';
    let data: any = await this.request(endpoint);
    let procedimento = data.find(x => x.Nome.toUpperCase().includes(proced));
    if(!procedimento){
      return false;
    } 
    return procedimento.Id;
  }

  async getEspecialidade(unidade, convenio, esp: string){
    this.setAutToken("", "");
    let endpoint = 'EspecialidadeIntegracao/Pesquisar';
    let vals = {
      "IdUnidade": unidade.toString(),
      "IdConvenio": convenio.toString()
    }
    let data: any = await this.request(endpoint, vals);
    let especialidade = data.find(x => x.Nome.toUpperCase().includes(esp));
    if(!especialidade){
      return false;
    } 
    return especialidade.Id;
  }

  async getHorarios(unidade, convenio, especialidade, procedimento){
    this.setAutToken("", "");
    let endpoint = 'AgendamentoIntegracao/ConsultarHorariosDisponiveis';
    let vals = {
      "IdUnidade": unidade.toString(),
      "IdConvenio": convenio.toString(),
      "IdEspecialidade": especialidade.toString(),
      "IdProcedimento": procedimento.toString()
    }
    console.log(vals)
    let data: any = await this.request(endpoint, vals);
    return data.Obj;
  }

  async getPaciente(nome, nascimento, cpf){
    this.setAutToken("", "");
    let endpoint = 'PacienteIntegracao/Buscar';
    let vals = {
      "NomeCompleto": nome,
      "DataNascimento": nascimento,
      "CPF": cpf
    }
    console.log(vals);
    let data: any = await this.request(endpoint, vals).then((data: any) =>{
      return data.Id;
    }).catch((err: any) =>{
      return 'Paciente não econtrado';
    });
  }

  async setPaciente(nome, nascimento, cpf){
    this.setAutToken("", "");
    let endpoint = 'PacienteIntegracao/Inserir';
    let vals = {
      "Nome": nome,
      "DataNascimento": nascimento,
      "CPF": cpf
    }
    let data: any = await this.request(endpoint, vals);
    return data;
  }

  async marcarAgendamento(unidade, convenio, especialidade, procedimento, paciente, profissional, profissionalHora, dataAgendamento){
    this.setAutToken("", "");
    let endpoint = 'AgendamentoIntegracao/Agendar';
    let vals = {
      "IdUnidade": unidade.toString(),
      "IdConvenio": convenio.toString(),
      "IdEspecialidade": especialidade.toString(),
      "IdProcedimento": procedimento.toString(),
      "IdPaciente": paciente,
      "IdProfissional": profissional,
      "IdProfissionalHorario": profissionalHora,
      "Data": dataAgendamento
    }
    let data: any = await this.request(endpoint, vals);
    return data;
  }

}
