import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { config } from './configs/config';
import { DataService } from './services/data.service';
import { Platform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  private user: any;
  constructor(
    private storage: Storage,
    private service: DataService,
    private platform: Platform,
    
  ) {
    this.initializeApp();
  }

  isCordovaAvailable(){
    if (!(<any>window).cordova) {
      console.log('This is a native feature. Please use a device');
      return false;
    }
    return true;
  }

  initializeApp(){
    this.platform.ready().then(async ()=>{
      await this.storage.create();
      this.user = await this.service.getStorage('usuario');
      if(this.isCordovaAvailable() && this.user){
        OneSignal.setAppId(config.oneSignalAppId);
        OneSignal.setExternalUserId("iExame_"+this.user.id);
        OneSignal.addSubscriptionObserver((state) =>{
          console.log(state);
          if(state.to.userId != this.user.one_signal_id){
            console.log("Att")
            this.service.requestPost({
              id: this.user.id,
              oneSignal: state.to.userId
            }, 'onesignal');
          }
        });
      }
    });
  }

  async ngOnInit() {
    
  }
}
