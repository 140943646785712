import { Injectable, NgZone } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private fireService: AngularFireAuth, 
    private router: Router,
    private zone: NgZone
  ){}

  canActivate():  Promise<boolean> {
    return new Promise((resolve) =>{
      this.fireService.onAuthStateChanged((user) =>{
        if(user){
          resolve(true);
        }else{
          this.zone.run(()=>{
            this.router.navigate(['auth'], {replaceUrl: true});
          });
        }
      })
    });
  }
  
}
