import { Component, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
})
export class FiltroComponent implements OnInit {
  public lvl: any = this.navParam.data.lvl;
  public lbl: any = this.navParam.data.desc; 
  private modal: any = this.navParam.data.modal;
  public de = '';
  public ate = '';
  public cartao: boolean;
  public dinheiro: boolean;
  public pix: boolean;
  public counter: number = 30;
  private filtro: any = [];
  public km = this.counter + "Km";
  public formasPagamento = [{
    lbl: 'Cartão de crédito e débito',
    ng: 'cartao'
  },{
    lbl: 'Dinheiro',
    ng: 'dinheiro'
  },{
    lbl: 'Pix',
    ng: 'pix'
  }];
  constructor(private navParam: NavParams, private service: DataService) { }

  async ngOnInit() {
    this.filtro = await this.service.getStorage('filtro');
    if(!this.filtro){
      this.filtro = {
        distancia: 50,
        preco: {
          min: 1,
          max: 100
        },
        forma_pagamento: {
          cartao: true,
          dinheiro: true,
          pix: true
        }
      };
    }
    this.de = this.filtro.preco.min;
    this.ate = this.filtro.preco.max;
    this.counter = this.filtro.distancia;
    this.km = this.counter + 'Km';
    this.cartao = this.filtro.forma_pagamento.cartao;
    this.dinheiro = this.filtro.forma_pagamento.dinheiro;
    this.pix = this.filtro.forma_pagamento.pix;
  }

  addRemove(val){
    if(val == 1){
      if(this.counter == 0){
        return false;
      }
      this.counter -= 1;
    }else{
      this.counter += 1;
    }
    this.km = this.counter + 'Km';
  }

  close(){
    this.modal.dismiss();
  }

  alterarFormaPagamento(lvl){
    if(lvl == 1){
      if(this.cartao){
        this.cartao = false;
        return false;
      }
      this.cartao = true;
    }else if(lvl == 2){
      if(this.dinheiro){
        this.dinheiro = false;
        return false;
      }
      this.dinheiro = true;
    }else if (lvl == 3){
      if(this.pix){
        this.pix = false;
        return false;
      }
      this.pix = true;
    }
  }

  async salvar(){
    this.filtro.distancia = this.counter;
    this.filtro.preco.max = this.ate;
    this.filtro.preco.min = this.de;
    this.filtro.forma_pagamento.cartao = this.cartao;
    this.filtro.forma_pagamento.dinheiro = this.dinheiro;
    this.filtro.forma_pagamento.pix = this.pix;
    await this.service.setStorage('filtro', this.filtro);
    this.modal.dismiss({new: true, filtro: this.filtro});
  }

}
