import { Component, OnInit } from '@angular/core';
import { NavParams, AlertController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cartoes',
  templateUrl: './cartoes.component.html',
  styleUrls: ['./cartoes.component.scss'],
})
export class CartoesComponent implements OnInit {
  public tipo: any = this.navParam.data.tipo_cartao;
  private modal: any = this.navParam.data.modal;

  public nome_cartao;
  public numero_cartao;
  public val_cartao;
  public cvc_cartao;

  public nome;
  public email;
  public cpf;
  public telefone;
  public complemento;
  public cep;
  public numero;
  
  public proprietario = "1";

  constructor(
    private navParam: NavParams,
    private alertCtrl: AlertController,
    private service: DataService
  ) { }

  ngOnInit() {
    
  }

  async alertPresent(){
    let alert = await this.alertCtrl.create({
      message: 'Todos os campos devem ser preenchidos'
    });
    return await alert.present();
  }

  async adicionarCartao(){
    let cards: any = await this.service.getStorage('cards');
    if(!cards){
      cards = [];
    }
    if(!this.nome_cartao || !this.numero_cartao  || !this.val_cartao || !this.cvc_cartao){
      await this.alertPresent();
      return false;
    }
    if(this.proprietario == '0' && (!this.nome || !this.email || !this.cpf || !this.telefone || !this.cep || !this.numero)){
      await this.alertPresent();
      return false;
    }
    let holderData;
    if(this.proprietario == '1'){
      let user: any = await this.service.getStorage('usuario');
      holderData = {
        name: user.nome, 
        email: user.email,
        cpfCnpj: user.cpf,
        postalCode: user.cep,
        addressNumber: user.numero,
        addressComplement: user.complemento,
        phone: user.telefone
      };
    }else{
      holderData = {
        name: this.nome, 
        email: this.email,
        cpfCnpj: this.cpf,
        postalCode: this.cep,
        addressNumber: this.numero,
        addressComplement: this.complemento,
        phone: this.telefone
      };
    }
    let newCard = {
      tipo_cartao: this.tipo,
      holderName: this.nome_cartao,
      number: this.numero_cartao,
      expiryMonth: this.val_cartao.split('/')[0],
      expiryYear: '20' + this.val_cartao.split('/')[1],
      ccv: this.cvc_cartao,
      proprietario: this.proprietario,
      holderData: holderData
    };
    cards.push(newCard);
    await this.service.setStorage('cards', cards);
    this.modal.dismiss({success: true});
  }

  cancelar(){
    this.modal.dismiss();
  }

}
