import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-disponibilidade',
  templateUrl: './disponibilidade.component.html',
  styleUrls: ['./disponibilidade.component.scss'],
})
export class DisponibilidadeComponent implements OnInit {
  private data: any = this.navParams.data; 
  private modal: any = this.data.modal;
  public dataSelecionada: string = this.data.dataSelecionada;
  public disponibilidades: any = this.data.disponibilidade;
  public horaSelecionada: string = null;
  public disabled: boolean = true;

  constructor(private navParams: NavParams) { }

  close(){
    this.modal.dismiss();
  }

  confirmar(){
    if(!this.horaSelecionada){
      return false;
    }
    this.modal.dismiss({confirm: true, horaSelecionada: this.horaSelecionada});
  }

  disableBtn(){
    if(this.horaSelecionada){
      this.disabled = false;
    }
  }

  ngOnInit() {}

  returnHora(hora){
    return hora.slice(0, -3);
  }
  
}
