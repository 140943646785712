import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { FiltroComponent } from './components/filtro/filtro.component';
import { ExameComponent } from './components/exame/exame.component';
import { DisponibilidadeComponent } from './components/disponibilidade/disponibilidade.component';
import { DetalhesExameComponent } from './components/detalhes-exame/detalhes-exame.component';
import { CartoesComponent } from './components/cartoes/cartoes.component';
import { CartComponent } from './components/cart/cart.component';
import { StatusExameComponent } from './components/status-exame/status-exame.component';
import { PagamentoComponent } from './components/pagamento/pagamento.component';
import { AgendamentoPage } from './pages/agendamento/agendamento.page';

import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { firebaseConfig } from './configs/firebase';

import { BrMaskerModule } from 'br-mask';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import localePt from '@angular/common/locales/pt';

import { registerLocaleData } from '@angular/common';


import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';

import { CommonModule } from '@angular/common';
import { NgCalendarModule } from 'ionic2-calendar';

registerLocaleData(localePt);


@NgModule({
  declarations: [
    AppComponent,
    FiltroComponent,
    ExameComponent,
    DisponibilidadeComponent,
    DetalhesExameComponent,
    CartoesComponent,
    CartComponent,
    StatusExameComponent,
    PagamentoComponent,
    AgendamentoPage
  ],
  entryComponents: [
    FiltroComponent,
    ExameComponent,
    DisponibilidadeComponent,
    DetalhesExameComponent,
    CartoesComponent,
    CartComponent,
    StatusExameComponent,
    PagamentoComponent,
    AgendamentoPage
  ],
  imports: [
    BrowserModule, 
    FormsModule,
    CommonModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig), // imports firebase/app
    AngularFireAuthModule, // imports firebase/auth
    IonicStorageModule.forRoot(),
    BrMaskerModule,
    NgCalendarModule
  ],
  providers: [
    HTTP,
    Geolocation,
    Clipboard,
    InAppBrowser,
    NavParams,
    NativeStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt-BR'}
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
