import { Component, OnInit } from '@angular/core';
import { NavParams, ToastController } from '@ionic/angular';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss'],
})
export class PagamentoComponent implements OnInit {

  constructor(
    private navParams: NavParams,
    private clipboard: Clipboard,
    private toastCtrl: ToastController,
    private dom: DomSanitizer
  ) { }

  private data: any = this.navParams.data; 
  private modal: any = this.data.modal;
  public tipoPagamento = this.data.tipo;
  public cod = (this.data.tipo == 'BOLETO')? this.data.cobranca.identificationField : this.data.cobranca.payload;
  public base64 = (this.data.tipo == 'PIX')? this.data.cobranca.encodedImage : '';

  
  ngOnInit() {}

  close(){
    this.modal.dismiss();
  }

  async copiar(){
    this.clipboard.copy(this.cod);
    await this.toastSend("Código copiado com sucesso");
  }

  async toastSend(msg){
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000
    });
    return await toast.present();
  }

  getUrl(){
    let code = btoa(this.base64);
    return this.dom.bypassSecurityTrustResourceUrl('https://sistema.iexame.com.br/dados/qrcode/'+code);
  }

}
