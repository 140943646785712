import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

  constructor(
    private alertCtrl: AlertController,
    private navParam: NavParams
  ) { console.log(this.quant) }
  
  private dados: any = this.navParam.data.info; 
  private modal = this.dados.modal;
  private exame_id = this.dados.exame_id;
  private clinica_id = this.dados.clinica_id;
  private unidade_id = this.dados.unidade_id;
  public exame = this.dados.exame;
  public clinica = this.dados.empresa;
  public data = this.dados.data;
  public hora = this.dados.hora;
  public valor = this.returnDecimals(this.dados.valor);
  public preparo = this.dados.preparo;
  private quant: any = this.navParam.data.quantidade;
  public counter: number = this.quant;

  ngOnInit() {}

  returnDecimals(val){
    return parseFloat(val).toFixed(2).toString().replace('.', ',');
  }

  close(){
    this.modal.dismiss();
  }

  async confirmDel(){
    let alert = await this.alertCtrl.create({
      subHeader: 'Deseja remover esse exame?',
      buttons: [{
        role: 'cancel',
        text: 'Não'
      },{
        text: 'Sim',
        handler: () =>{
          let vals = {exame: this.exame_id, clinica: this.clinica_id, unidade: this.unidade_id, quant: this.quant - 1, original: this.quant};
          this.modal.dismiss({remove: true, vals: vals});
        }
      }]
    });
    return await alert.present();
  }

  stringAgendamento(data, hora){
    return data + " " + hora;
  }

  addRemove(val){
    if(val == 1){
      if(this.counter == 1){
        return false;
      }
      this.counter -= 1;
    }else{
      if(this.counter == this.quant){
        return false;
      }
      this.counter += 1;
    }
  }

}
