import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-detalhes-exame',
  templateUrl: './detalhes-exame.component.html',
  styleUrls: ['./detalhes-exame.component.scss'],
})
export class DetalhesExameComponent implements OnInit {
  private data: any = this.navParams.data; 
  private modal: any = this.data.modal;
  public exame: string = this.data.exame;
  public clinica: string = this.data.clinica;
  public dia: string = this.data.data;
  public hora: string = this.data.hora;
  public endereco: string = this.data.endereco;
  public complemento: string = this.data.complemento;
  public registro: string = this.data.data_registro;

  constructor(private navParams: NavParams) {}

  ngOnInit() {}

  close(){
    this.modal.dismiss();
  }

}
