import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private fireService: AngularFireAuth,
    private router: Router,
    private zone: NgZone
  ){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve) =>{
        this.fireService.onAuthStateChanged((user) =>{
          if(user){
            this.zone.run(()=>{
              this.router.navigate(['app'], {replaceUrl: true});
            });
          }
          resolve(!user? true : false);
        })
      });
  }
  
}
