import { Injectable } from '@angular/core';
import { config } from '../configs/config';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpClient } from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Storage } from '@ionic/storage';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private url = config.url;
  private headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'POST, GET, PUT, OPTIONS, DELETE, PATCH',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
  };

  constructor(
    private http: HTTP, 
    private wStorage: NativeStorage, 
    private client: HttpClient,
    private storage: Storage,
    private geolocation: Geolocation,
  ) {}

  isCordovaAvailable(){
    if (!(<any>window).cordova) {
      return false;
    }
      return true;
  }

  async checkLocation(){
    return new Promise((resolve, reject) =>{
      Diagnostic.isLocationAvailable().then((success) =>{
        resolve(success);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async requestLocation(){
    await Diagnostic.requestLocationAuthorization().then((success) =>{
      console.log(success);
    }).catch((err) =>{
      console.log(err);
    })
  }

  async requestGet(vals, endpoint){
    return new Promise((resolve, reject) =>{
      this.client.get(this.url + endpoint, vals).subscribe((data)=>{
        let parsedData = data;
        resolve(parsedData);
      },
      (err) =>{
        console.log(err);
        reject(err);
      });
    });
    /*  if(this.isCordovaAvailable()){
      return new Promise((resolve, reject) =>{
        this.http.get(this.url + endpoint, vals, this.headers).then((data)=>{
          let parsedData = JSON.parse(data['data']);
          console.log(parsedData);
          resolve(parsedData);
        }).catch((err) =>{
          console.log(err);
          reject(err);
        });
      });
    }else{
      
    } */
  }

  async requestPost(vals, endpoint){
    return new Promise((resolve, reject) =>{
      this.client.post(this.url + endpoint, vals).subscribe((data)=>{
        let parsedData = data;
        resolve(parsedData);
      },
      (err) =>{
        console.log(err);
        reject(err);
      });
    });
    /* if(this.isCordovaAvailable()){
      return new Promise((resolve, reject) =>{
        this.http.post(this.url + endpoint, vals, this.headers).then((data)=>{
          let parsedData = JSON.parse(data['data']);
          console.log(parsedData);
          resolve(parsedData);
        }).catch((err) =>{
          console.log(err);
          reject(err);
        });
      });
    }else{
      
    } */
  }

  async setStorage(key, arr){
    await this.storage.set(key, arr);
  }

  async getStorage(key){
    return new Promise((resolve, reject) =>{
      let s = this.storage.get(key).then((res)=>{
        resolve(res);
      }).catch((error) =>{
        reject(error);
      });
    });
  }

  async removeStorage(key){
    await this.storage.remove(key);
  }

  async requestMap(local){
    let url = 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input='+local+'&inputtype=textquery&fields=photos,formatted_address,name,rating,opening_hours,geometry&key=AIzaSyAelxEQIUnIZxkXVahNXDvHUxTCHmgl-eo';
    return new Promise((resolve, reject) =>{
      this.client.get(url, {}).subscribe((res)=>{
        console.log(res);
      }, (err)=>{
        console.log(err);
      });
    });
    /* if(this.isCordovaAvailable()){
      return new Promise((resolve, reject) =>{
        this.http.get(url, {}, {}).then((data)=>{
          let parsedData = JSON.parse(data['data']);
          console.log(parsedData);
          resolve(parsedData);
        }).catch((err) =>{
          console.log(err);
          reject(err);
        });
      });
    }else{
      
    } */
  }

  async viacep(cep){
    return new Promise((resolve, reject) =>{
      this.client.get('https://viacep.com.br/ws/'+cep+'/json/', {}).subscribe((data)=>{
        let parsedData = data;
        resolve(parsedData);
      },
      (err) =>{
        console.log(err);
        reject(err);
      });
    });
    /* if(this.isCordovaAvailable()){
      return new Promise((resolve, reject) =>{
        this.http.get('https://viacep.com.br/ws/'+cep+'/json/', {}, this.headers).then((data)=>{
          let parsedData = JSON.parse(data['data']);
          console.log(parsedData);
          resolve(parsedData);
        }).catch((err) =>{
          console.log(err);
          reject(err);
        });
      });
    }else{
      
    } */
  }

  async getCurrentLocation(){
    return new Promise((resolve, reject) =>{
      this.geolocation.getCurrentPosition().then((response: any) =>{
        resolve(response.coords);
      }).catch((err: any) =>{
        reject(err);
      });
    });
  }

  async getIp(){
    return new Promise((resolve, reject) =>{
      this.client.get('https://api.ipify.org/?format=json', {}).subscribe((response) =>{
        resolve(response);
      }, (err) =>{
        reject(err);
      })
    });
  }

}
