import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { CalendarComponent } from 'ionic2-calendar';
import { ModalController } from '@ionic/angular';
import { DisponibilidadeComponent } from 'src/app/components/disponibilidade/disponibilidade.component';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-agendamento',
  templateUrl: './agendamento.page.html',
  styleUrls: ['./agendamento.page.scss'],
})
export class AgendamentoPage implements OnInit {

  eventSource = [];
  viewTitle: string;
  private horaModal: any;

  calendar ={
    mode: 'month',
    currentDate: this.amanha(),
    diaSemana: "EEEEE"
  };
  
  datas = this.navParams.data.disponibilidade;
  dados = this.navParams.data.dados;

  private nomeMes = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

  private nomeSemana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

  selectedDate: Date;
  
  @ViewChildren(CalendarComponent) myCal: CalendarComponent;

  public pagamento;
  private modAgendamento: any = this.navParams.data.modal;
  public exame: any;
  public valor: any;
  public distancia: any;
  public horario: any;
  private dataSelecionada: any = null;
  public dataFormatada: any = '';
  public horaSelecionada: any = null;
  public dF: boolean = false;
  showCalendar = false;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.showCalendar = true;
  }

  amanha(){
    let hoje = new Date();
    let amanha = new Date(hoje);
    amanha.setDate(amanha.getDate() + 1);
    return hoje;
  }

  next(){
    this.myCal.slideNext();
  }

  back(){
    this.myCal.slidePrev();
  }

  onViewTitleChanged(title){
    let d = title.split(" ");
    this.viewTitle = d[0].charAt(0).toUpperCase() + d[0].slice(1);
  }

  markDisabled = (date: Date) => {
    let data = this.returnMysqlDate(date);
    let existe = this.datas.some(x => x.data == data);
    return !existe;
  };

  returnMysqlDate(ev: Date){
    return (ev.getFullYear() + '-' + ('0' + (ev.getMonth()+1)).slice(-2) + '-' + ('0' + ev.getDate()).slice(-2));
  }

  async onTimeSelected(event:any){
    if(event.disabled){
      return false;
    }
    let ev: Date = event.selectedTime;
    let mySqlDate = (ev.getFullYear() + '-' + ('0' + (ev.getMonth()+1)).slice(-2) + '-' + ('0' + ev.getDate()).slice(-2));// ev.getFullYear() + "-" + (ev.getMonth() + 1) + "-" + ev.getDate();
    console.log(mySqlDate);
    this.dataSelecionada = mySqlDate;
    this.dataFormatada = this.nomeSemana[ev.getDay()] + ", " + ev.getDate() + " de " + this.nomeMes[ev.getMonth()];
    let array = this.datas.find(x => x.data == mySqlDate);
    await this.selecionarHora(this.dataFormatada, array.disponibilidade);
  };

  async selecionarHora(dataSelecionada, disponibilidade){
    this.horaModal = await this.modalCtrl.create({
      component: DisponibilidadeComponent, 
      backdropDismiss: true,
      componentProps: { 
        dataSelecionada: dataSelecionada,
        disponibilidade: disponibilidade
      },
      cssClass: 'modal-filtro'
    });
    this.horaModal.onDidDismiss().then((data) =>{
      if(data.data){
        if(data.data.confirm){
          this.dF = true;
          this.horaSelecionada = data.data.horaSelecionada + ":00";
          this.dataFormatada += " às " + data.data.horaSelecionada;
        }
      }
    });
    return await this.horaModal.present();
  }

  cancelar(){
    this.modAgendamento.dismiss({conf: 'cancelado'});
  }

  confirmar(){
    let date = this.dataSelecionada + "T" + this.horaSelecionada;
    let dataAgendamento = this.dados.find(x => x.DataHora == date);
    this.modAgendamento.dismiss({
      conf: 'aprovado', 
      data: this.dataSelecionada,
      hora: this.horaSelecionada, 
      dataFormatada: this.dataFormatada,
      dadosAgendamento: dataAgendamento
    });
  }

}
