import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { AgendamentoPage } from 'src/app/pages/agendamento/agendamento.page';

@Component({
  selector: 'app-exame',
  templateUrl: './exame.component.html',
  styleUrls: ['./exame.component.scss'],
})
export class ExameComponent implements OnInit {
  private data: any = this.navParams.data; 
  private modal: any = this.data.modal;
  private exame_id: any = this.data.exame_id;
  private clinica_id: any = this.data.clinica_id;
  private user_id: any = this.data.user_id;
  private unidade_id: any = this.data.unidade_id;
  public exame: any = this.data.exame;
  public valor: any = this.data.valor;
  private valor_real: any = this.data.valor_real;
  public distancia: any = this.data.distancia;
  public preparo: any = this.data.preparo;
  public agendamento: any = this.data.agendamento;
  private agendamentoModal: any;
  public horario_funcionamento: any = this.data.horario_funcionamento;
  public formatedVal: any = parseFloat(this.valor).toFixed(2).toString().replace('.', ',');
  public horaAgendada: any;
  public dataAgendada: any;
  public counter: number = 1;
  private quantidade: number = 0;
  private cart: any = this.data.cart;
  constructor(
    private navParams: NavParams, 
    private modalCtrl: ModalController) {
      if(this.cart){
        let quant = this.cart.filter(x => (x.exame_id == this.exame_id) && (x.unidade_id == this.unidade_id));
        this.quantidade = quant.length;
        if(quant.length > 0){
          this.counter = quant.length;
        }
      }else{
        this.counter = 1;
      }
    }

  ngOnInit() {console.log()}

  close(){
    this.modal.dismiss();
  }

  addRemove(val){
    if(val == 1){
      if(this.counter == 0){
        return false;
      }
      this.counter -= 1;
    }else{
      this.counter += 1;
    }
  }

  confirmar(valor, distancia, horario){
    let vals = {
      exame_id: this.exame_id,
      clinica_id: this.clinica_id,
      user_id: this.user_id,
      valor: valor,
      valor_real: this.valor_real,
      distancia: distancia,
      horario: horario,
      quant: this.counter,
      original: this.quantidade
    };
    this.modal.dismiss({conf: true, vals: vals});
  }

  async marcarAgendamento(){
    if(this.agendamentoModal){
      this.agendamentoModal.dismiss();
    }
    this.agendamentoModal = await this.modalCtrl.create({
      component: AgendamentoPage, 
      backdropDismiss: false,
      componentProps: {

      },
      cssClass: 'modal-filtro'
    });
    this.agendamentoModal.onDidDismiss().then((data) =>{
      console.log(data);
      if(data.data){
        if(data.data.conf == 'aprovado'){
          
        }
      }
    });
    return await this.agendamentoModal.present();
  }

}
